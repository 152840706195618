@import "~antd/dist/antd.css";
/* .export-btn-holder{
    position: relative;
} */

@font-face {
  font-family: "GeneralSans-Bold";
  src: local("GeneralSans-Bold"),
    url("./Fonts/WEB/fonts/GeneralSans-Regular.ttf") format("truetype");
  /* font-weight: bold; */
}


@font-face {
  font-family: "GeneralSans-Regular";
  src: local("GeneralSans-Regular"),
    url("./Fonts/WEB/fonts/GeneralSans-Regular.ttf") format("truetype");
  /* font-weight: bold; */
}

@font-face {
  font-family: "GeneralSans-Medium";
  src: local("GeneralSans-Medium"),
    url("./Fonts/WEB/fonts/GeneralSans-Medium.ttf") format("truetype");
  /* font-weight: bold; */
}

@font-face {
  font-family: "GeneralSans-SemiBold";
  src: local("GeneralSans-SemiBold"),
    url("./Fonts/WEB/fonts/GeneralSans-Semibold.ttf") format("truetype");
  /* font-weight: bold; */
}

.goog-logo-link {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
}

.goog-te-gadget .goog-te-combo {
  color: blue !important;
}


body::-webkit-scrollbar {
  display: none;
}

#root {
  /* font-family: "GeneralSans"; */
  height: 100%;
}



.box-size {
  height: 100%;
}


@media screen and (max-width: 900px) and (min-width: 600px) {

  .login-bound-font {
    font-size: 2rem
  }

}