.row__aob {
  padding: 5% 5% 5% 10%;
}
.oona_aob_font {
  font-family: "GeneralSans-Medium";
}
.oona_aob_font-medium {
  font-family: "GeneralSans-Medium";
  font-size: 14px;
}

.oto__bold {
  font-weight: 900;
}

.aob__register label {
  font-family: "GeneralSans-SemiBold";
}
.otp__confir label {
  font-family: "GeneralSans-SemiBold";
}

.aob__idcard label {
  font-family: "GeneralSans-SemiBold";
}
.aob__taxpayer label {
  font-family: "GeneralSans-SemiBold";
}
.withoutReferral label {
  font-family: "GeneralSans-SemiBold";
}
.aob__register .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-family: "GeneralSans-SemiBold";
}
.row__aob__inside {
  padding: 1% 3% 1% 3%;
}
.aob__checkbox {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.oona__small__fontSize {
  font-size: 15px;
}
.oona__parent {
  margin-top: 1.5rem;
}
.aob__checkbox__web {
  display: flex;
  width: 100%;
}

.aob__checkbox__gap {
  margin-right: 10px;
}
.aob__checkbox__span {
  color: #272d33;
  font-weight: 500;
  font-size: 1em;
  text-align: start;
}
.aob__checkbox__span span {
  color: #482c77;
  font-size: 1em;
  font-weight: 600;
}

.header_card_content_description {
  color: #343d46;
  font-weight: 500;
  font-size: 1rem;
}

.upload__photo__section__mobile {
  border: 2px dotted #d9d9d9;
  padding: 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 6px;
}

.upload__photo__section__web {
  border: 2px dotted #d9d9d9;
  padding: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 10px;
}

.oona__aob .ant-progress-outer {
  width: 139%;
}
.oona__aob__progress__text {
  margin-bottom: 1rem;
  color: #18191b;
  font-size: 12px;
}

.terms__font {
  font-size: 25px;
}

.upload__photo__section__web__single {
  border: 2px dotted #d9d9d9;
  padding: 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 6px;
}

.upload__photo__section__mobile__header {
  font-weight: 600;
  font-size: 14px;
  color: #343d46;
  margin-bottom: 1%;
}

.upload__photo__section__mobile__footer {
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #482c77;
}

.aob__termsandConditions__mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.aob__termsandConditions__mobile__content {
  margin-bottom: 1rem;
}

.aob__termsandConditions__mobile__content__title {
  color: #482c77;
  font-weight: 600;
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

.aob__termsandConditions__mobile__content__description {
  color: #272d33;
  font-weight: 500;
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.aob__terms__context__space {
  margin-top: 1rem;
}

.aob__termsandConditions__mobile__content__description > div {
  margin-bottom: 0.8rem;
}

.aob__termsandConditions__web {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.aob__termsandConditions__web__content__title {
  color: #482c77;
  font-weight: 600;
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

.aob__termsandConditions__web__content__description > div {
  margin-bottom: 0.8rem;
}

.upload__Signature__section__mobile {
  border: 2px dotted #d9d9d9;
  padding: 17%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 10px;
  /* background-color: yellow; */
}

.upload__Signature__section__web__single {
  margin-top: 0.5rem;
  border: 2px dotted #d9d9d9;
  padding: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 10px;
}
.upload__Signature__section__web__single > div {
  width: 100%;
  height: 100%;
}

/* .idCard__signature{
 height:100%;
width:270px;
background-color: yellow;
} */

.individualaob__modalPOpup__headerone {
  font-weight: 700px;
  font-size: 1.3rem;
  text-align: center;
  color: #000000;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* .individualaob__modalPOpup__headerTwo{
    display: flex;
    flex-direction: column;
    margin-bottom: 1.50rem;
    
}
.individualaob__modalPOpup__headerTwo>div{
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.3rem;
    text-align: center;
} */

.individualaob__button__row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* margin-bottom: 1rem; */
  /* height:70px; */

  /* height: 276px;
    background-color: #21153A; */
}

.drawer__oona__AOB__header__one {
  line-height: 30px;
}

.drawer__oona__AOB__header__one > div:nth-child(1) {
  font-weight: 600;
  font-size: 25px;
  /* background-color: yellow; */
  padding: 10px 6px;
  text-align: center;
  color: #000000;
}

.drawer__oona__AOB__header__one h2 {
  /* background-color: yellow; */
}

.drawer__oona__AOB__header__one > div:nth-child(2) {
  font-weight: 500;
  font-size: 14px;
  /* background-color: yellow; */
  padding: 10px 6px;
  text-align: center;
  color: #343d46;
  line-height: 21px;
}

.drawer__Coponent__oona .ant-drawer-content-wrapper {
  height: 276px !important;
}
.drawer__Coponent__oona .ant-drawer-content {
  border-top-left-radius: 12px !important;
}

.logo__suucess {
  display: flex;
}

.oonaSuccess__kahoona {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 414px;
  width: 360px;
  gap: 24px;
}

.oonaSuccess__ocrkahoona {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100px;
  width: 360px;
  gap: 24px;
}

.oonaError__kahoona {
  display: flex;
  flex-direction: column;
  height: 360px;
  width: 321px;
  gap: 24px;
}

.oonaSuccess__kahoona__mobile {
  padding: 1rem;
}

.oonaSuccess__kahoona__mobile__context {
  height: 154px;
}

.oonaSuccess__kahoona__mobile__context__header {
  font-size: 35px;
  font-weight: 600;
  line-height: 49px;
  letter-spacing: 0em;
  text-align: center;
  color: #482c77;
}
.oonaSuccess__kahoona__mobile__context__header__text {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  padding: 0.3rem;
}

.oonaSuccess__kahoona__header {
  font-weight: 600;
  font-size: 41px;
  line-height: 49.2px;
  color: #482c77;
  margin-bottom: 0.5rem;
  margin-bottom: -1rem;
}

.oonaSuccess__ocrkahoona__header{
  font-weight: 600;
  font-size: 41px;
  line-height: 49.2px;
  color: #482c77;
  margin-bottom: 0.5rem;
  margin-bottom: -1rem;

}
.oonaSuccess__kahoona__header__download__mobile {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}
.oonaSucess__images__mobile {
  display: flex;
  justify-content: space-between;
}
/* .oonaSucess__images__mobile>div{
    width: 129.64px;
     height: 44px

} */
.oona__success_buttons {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.oona__success_buttons > button:nth-child(1) {
  background-color: #ffffff;
  border-color: #482c77 !important;
  color: #8c5acb;
  border-radius: 10px;
  width: 100%;
  height: 36px;
  margin-top: 10px;
  margin-bottom: 10px;
  float: right;
  text-align: center;
}

.oona__success_buttons > button:nth-child(2) {
  background-color: #482c77;
  border-color: #482c77 !important;
  color: #ffffff;
  border-radius: 10px;
  width: 100%;
  height: 36px;
  margin-top: 10px;
  margin-bottom: 10px;
  float: right;
  text-align: center;
}

.oona__success_buttons__referral > button:nth-child(1) {
  background-color: #4ac6bb;
  border-color: #4ac6bb !important;
  color: #ffffff;
  border-radius: 10px;
  width: 100%;
  height: 36px;
  /*margin-top: 10px;
    margin-bottom: 10px;
    float: right;
    text-align: center; */
}

.oonaSuccess__kahoona__header__cenetered {
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
  width: 360px;
  height: 120px;
  width:100%
}

.oonaSuccess__kahoona__header__cenetered div:nth-child(1) {
  margin-bottom: 1rem;
}
.oonaError__kahoona__header__cenetered {
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 360px;
  height: 120px;
}

.oonaSuccess__kahoona__header__cenetered > div {
  color: #18191b;
}
.oonaSuccess__kahoona__header__download {
  color: #18191b;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: -1rem;
}
.logo__suucess {
  display: flex;
  justify-content: space-between;
  width: 95%;
}
.oonna_success_buuton {
  display: flex;
  justify-content: space-between;
}

.oonna_success_buuton__home {
  /* padding:6px 16px 6px 16px  */
  background-color: #ffffff;
  border-color: #482c77 !important;
  color: #8c5acb;
  border-radius: 10px;
  width: 48%;
  height: 46px;
  margin-top: 10px;
  margin-bottom: 10px;
  float: right;
  text-align: center;
}

.oonna_success_buuton__Invite {
  background-color: #482c77;
  border-color: #482c77 !important;
  color: #ffffff;
  border-radius: 10px;
  width: 48%;
  height: 46px;
  margin-top: 10px;
  margin-bottom: 10px;
  float: right;
  text-align: center;
}

/* .drawer__Coponent__oona  div:nth-child(2){
    height:276px !important
} */

/* .individualaob__button__row>button{
    width:48%;
    border:1px solid #DCD6E9 !important;
    padding:1.5rem !important;
    

} */

/* .individualaob__button{

    display: flex;
    background-color: yellow;
} */

/* .individualaob__button > button{
    width: 100%;
    padding:12px 16px 12px 16px;
    border-radius: 7px;
    border-color: #DCD6E9;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 15px;
    } */
.oona__tab .ant-tabs-tab .ant-tabs-tab-btn {
  color: #343d46 !important;
  font-weight: 800;
}

.oona__tab .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #673fae !important;
}
.oona__tab .ant-tabs-tab {
  border-bottom: 1px solid #e1e2e2;
}

.tax__padding > h5 {
  padding: 1% 0% 1% 0% !important;
  /* background-color: yellow; */
}
.font__size__idCard {
  font-size: 23px !important;
}
.upload__photo__section__web > button {
  border: transparent;
}
.individualaob__button__row > button {
  /* background-color:#482c77; */
  border-color: #dcd6e9 !important;
  /* color: white; */
  border-radius: 12px;
  width: 48%;
  height: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  font-family: "GeneralSans-Medium";
}
.oonaAob__modal__button {
  width: 600px !important;
}
.oonaAob__modal__button__header {
  width: 536px;
}
.individualaob__modal__header {
  margin-bottom: 5px;
}
.individualaob__modal__header > h5 {
  text-align: center;
  /* padding:1% 13% 1% 13%; */
  padding: 1% 16% 1% 16%;
  font-family: "GeneralSans-Medium";
  font-size: 23px;

  /* background-color: yellow; */
}

.individualaob__modalPOpup__headerTwo {
  width: 536px;
}
.individualaob__modalPOpup__headerTwo > div {
  font-family: "GeneralSans-Medium";
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #343d46;
}

.individualaob__button__row > button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.individualaob__button__row > button > span:nth-child(1) {
  width: 10%;
  /* background-color: red;*/
}
.individualaob__button__row > button > span:nth-child(2) {
  width: 80%;
  font-weight: 600;
  font-size: 18px;
  color: #21153a;
  /* background-color: yellow; */
}
.ant-modal-content {
  border-radius: 16px !important;
}

.ant-modal-footer {
  display: none;
}
.ant-modal-header {
  display: none;
}

.individualaob__drawer__headerone {
  color: #000000;
  text-align: center;
}

.individualaob__drawer__headerTwo {
  color: #343d46;
  font-weight: 500;
  text-align: center;
}
.individualaob__drawer__headerTwo__paragraph {
  /* background-color: yellow; */
  margin-top: -0.5rem;
}

.individualaob__drawer__button__row {
  display: flex;
  justify-content: space-between;
}

.individualaob__button > button {
  padding: 10%;
}
.individualaob__drawer__headerone__content {
  display: flex;
  flex-direction: column;
  /* justify-content: center;
        align-items: center; */
}

.aob__register .tab-inner-travel .ant-tabs-tab {
  min-width: 18rem;
}

@media screen and (max-width: 525px) {
  .aob__register .tab-inner-travel .ant-tabs-tab {
    min-width: 135px;
  }
}

/* @media only screen and (max-width: 374px) and (min-width: 321px)  {
            .oona-mob-non-selected{
                margin-right:8px !important
            }
    } */

.heading__content__oonaAOB {
  color: #482c77;
  font-weight: 600;
  font-size: 30px;
  margin-left: 5%;
}

.arrow__left__outlined {
  display: flex;
  width: 10%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.aob__marin__top {
  margin-top: 2%;
}

.next-button-oona {
  background-color: #482c77;
  border-color: #482c77 !important;
  color: white;
  border-radius: 10px;
  width: 27%;
  height: 48px;
  margin-top: 2rem;
  margin-bottom: 10px;
  float: right;
  text-align: center;
}

.next-button-oona__skip{
  /* background-color: #482c77;
  border-color: #482c77 !important; */
  color: #482c77;
  border-radius: 10px;
  width: 27%;
  height: 48px;
  margin-top: 2rem;
  margin-bottom: 10px;
  float: right;
  text-align: center;
  margin-right:1em

}

.oona-mob-non-selected {
  background-color: rgb(175, 164, 164);
  color: white;
  /* padding: 0px 9px; */
  margin-right: 5px;
  border-radius: 25px;
  text-align: center;
}

.oona-mob-selected {
  background-color: #4ac6bb;
  color: white;
  text-align: center;
  border-radius: 25px;
  margin-right: 5px;
}

.oona-heading-content {
  color: #482c77;
  font-weight: 600;
  font-size: 18px;
}

.oona__success__text__title {
  font-size: 2.8rem;
  font-weight: 600;
  color: #482c77;
  line-height: 49.2px;
}
.oona__success__text__middle {
  font-weight: 500;
  font-size: 1rem;
  line-height: 24px;
}
.CreatingAgentAccount__header {
  color: #482c77;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  margin-bottom: 1%;
  font-family: "GeneralSans-Bold";
}

.content-form-dashboard {
  inline-size: max-content;
}

.CreatingAgentAccount__passwordText {
  color: #343d46;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.CreatingAgentAccount__phoneNumber {
  color: #343d46;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

.agentOTPInput {
  border: none;
  outline: none;
  border-bottom: 1px solid #818f99;
  width: 96%;
  padding: 14px 8px 14px 8px;
  text-align: center;
}
.agentOTPInput:focus {
  border: none;
  outline: none;
  border-bottom: 1px solid #818f99;
}

.otpConfirmation__footer {
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #343d46;
}

.otpConfirmation__resendfooter {
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #482c77;
}

/* .aob__register .ant-modal-close{
    background-color: yellow;
  } */

.aob__modal__button {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  border: 1px solid #dcd6e9;
  border-radius: 12px;
}

.submit__onna__button {
  margin-top: 2rem;
  /* height:58px */
}

.oona__error {
  display: flex;
  flex-direction: column;
  line-height: 24px;
  text-align: center;
  color: #343d46;
}
.oona__error div:nth-child(2) {
  margin-top: 1rem;
}

.otp__font {
  font-size: 16px;
}

/* Media Query for low resolution  Tablets, Ipads */
/* @media (min-width: 481px) and (max-width: 767px) {
   .oona__aob__progress__text{
    background-color: yellow;
   }
} */

/* Media Query for Tablets Ipads portrait mode */
/* @media (min-width: 768px) and (max-width: 1024px){
    .oona__aob__progress__text{
        background-color: blue;
       }
} */

/* Medium devices (landscape tablets, 768px and up) */

/* @media screen and (min-width: 601)  and (max-width: 768px){
     .arrow__left__outlined{
        width:17%
     }
    
     .aob__register .tab-inner-travel .ant-tabs-tab{
        min-width:350px !important
     }
     

    } */

.Change__tier__parent {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.changetier__button {
  border-color: #dcd6e9 !important;
  border-radius: 12px;
  width: 48%;
  height: 40px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  font-family: "GeneralSans-Medium";
}

.changetier__button__confirm {
  background-color: #482c77;
  color: #dcd6e9;
  border-color: #dcd6e9 !important;
  border-radius: 12px;
  width: 48%;
  height: 40px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  font-family: "GeneralSans-Medium";
}

.change_Submit_Button {
  background-color: #482c77;
  color: white;
  width: 100%;
  padding: 6px, 16px, 6px, 16px;
  border-radius: 12px;
  height: 48px;
}

.CreatingAgentAccount__phoneNumber
  .ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: none !important;
}

.aob__register .ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: none !important;
}

.aob__taxpayer  .ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: none !important;
}



.change__tier {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
}


.next-button-oona__disabled{
  background-color: #C8CDD1;
  border-color: #C8CDD1 !important;
  color: #818F99;
  border-radius: 10px;
  width: 27%;
  height: 48px;
  margin-top: 2rem;
  margin-bottom: 10px;
  float: right;
  text-align: center;
}



.continue__button{
  background-color: #482C77;
  padding: 6px, 20px, 20px, 16px;
  border-radius: 12px;
  width: 100%;
  color: white;

}

.topmobileMargin{
  margin-top: 78px;
}