body::-webkit-scrollbar {
    display: contents;
    width: 5px;
    height: 300px;
    margin-left: 40px;

}

body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgb(153 131 131 / 30%);
}

body::-webkit-scrollbar-thumb {
    background-color: darkgrey;
}

/* model-scroll-bar */
.custom-scroll-content {
    height: 400px;
    overflow-y: scroll;
    margin-top: 20px;
}

/* Width and height of the scrollbar track */
.custom-scroll-content::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    display: contents;

}

/* Color of the scrollbar track */
.custom-scroll-content::-webkit-scrollbar-track {
    background: #f5f5f5;
}

/* Color of the scrollbar thumb (the draggable part) */
.custom-scroll-content::-webkit-scrollbar-thumb {
    background-color: rgb(197, 193, 193);
    border-radius: 5px;
}

/* When hovering over the scrollbar */
.custom-scroll-content::-webkit-scrollbar-thumb:hover {
    background-color: #7a5baf;
}

.custom-scroll-content .ant-radio-group {
    width: 100%;
}