.main-content-body {
    padding: 5% 18%;
}

.heading {
    font-size: 32px;
    font-weight: bold;
    color: #482c77;
    margin-top: 20px;
}

.ant-card-actions {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.ant-radio-button-wrapper:not(:first-child)::before {
    content: none;
}

.ant-radio-button-wrapper:last-child {
    border-radius: 50px;
}

.ant-radio-button-wrapper:first-child {
    border-radius: 50px;
    margin-left: 0px;
}

/* 
.top-card{
    border-left: 2px solid #f79838;
} */

.ant-input-search-button {
    height: 36px;
    border-left: none;
    border-right: 1px solid #818F99;
    border-top: 1px solid #818F99;
    border-bottom: 1px solid #818F99;
}

.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
    color: #4AC6BB;
}

.ant-radio-button-wrapper {
    margin-left: 10px;
    border-radius: 50px;
    border-left-width: 1px;
}

.ant-radio-button-wrapper:not(:first-child)::before {
    background-color: transparent;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border-radius: 50px;
}

.leads-card .ant-card-body {
    padding: 0px;
}

.leads-card {
    border-radius: 5px;
    margin-right: 10px;
    margin-bottom: 15px;
    box-shadow: 0px 8px 20px 0px #00000014;
}

.card-header {
    border-bottom: 1px solid #f2f2f3;
    width: 138%;
}

.card-body {
    /* padding: 10px; */
    width: 138%;

}

.header-text {
    padding: 10px;

    font-size: 13px !important;
    color: #87959e;
}

.header-right {
    float: right;
}

@media screen and (max-width:600px) {
    .tab-inner-travel .ant-tabs-tab {
        min-width: 135px;
    }

    .main-content-body {
        padding: 10px 10px;
    }
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: #fff;
    background: #8c5acb;
    border-color: #8c5acb;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #fff;
    background: #8c5acb;
    border-color: #8c5acb;
}

.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
    border-right-color: #8c5acb;
}

.ant-radio-button-wrapper:hover {
    position: relative;
    color: #fff;
}

.radio-leads .ant-radio-button-wrapper {
    color: #8c5acb !important;
    border-color: #8c5acb !important;
    height: 30px;
    line-height: 25px;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #fff !important;
}

.ant-input-search-button .ant-radio-inner::after {
    background-color: #8c5acb;
}

.ant-radio-checked .ant-radio-inner {
    border-color: #8c5acb;
}

.action .anticon svg {
    margin-top: -5px;
    margin-right: 5px
}