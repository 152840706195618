.loader_data{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgb(181 174 174 / 81%);
    z-index: 100;
}
.loader{
    left: 45%;
    top: 45%;
    z-index: 1000;
    position: absolute;
}